<template>
  <v-container fluid fill-height>
    <v-row align='center'>
      <v-col>
        <v-autocomplete
          v-model='report'
          :items='reports'
          :label='$t("SELECT_TO_ADD_REPORT")'
          item-text='name'
          return-object
          @change='selectReport'
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div ref='dash-grid' class='grid-stack' />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { GridStack } from 'gridstack';
  import 'gridstack/dist/gridstack.min.css';
  import 'gridstack/dist/h5/gridstack-dd-native';
  import 'gridstack/dist/jq/gridstack-dd-jqueryui';
  import axios from 'axios';
  import Swal from 'sweetalert2';

  let grid;
  // eslint-disable-next-line
  function removeItem(id) {
    grid.removeWidget(id);
  }

  export default {
    data: function () {
      return {
        grid: null,
        report: null,
        dashboardId: null,
        reports: [],
        serializedData: [],
      };
    },
    computed: {
    },
    mounted: async function () {
      try {
        document.removeItem = removeItem;
        // grid = GridStack.init({ removable: true });
        this.$store.dispatch('loading/toggleLoading');
        await this.fetchreports();
        await this.fetchDashboard();
        const tempDashReportsIds = this.serializedData.map((r) => r.reportID);
        this.reports = this.reports.filter((r) => tempDashReportsIds.indexOf(r.id) === -1);
        grid = await GridStack.init({ float: true });
        grid.on('change removed', () => {
          this.saveReport();
        });
        grid.load(this.serializedData, true);
        this.$store.dispatch('loading/toggleLoading');
        // this.saveReport();
        this.$forceUpdate();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        this.$store.dispatch('loading/toggleLoading');
      }
    },
    methods: {
      async fetchDashboard () {
        const response = await axios({
          url: '/dashboard',
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: 'GET',
          responseType: 'json',
          withCredentials: true,
        });
        if (response.data.dashboard.length > 0) {
          const reports = response.data.dashboard[0].data;
          this.dashboardId = response.data.dashboard[0].id;
          if (reports !== '') {
            this.serializedData = JSON.parse(reports);
          }
        }
      },
      async fetchreports () {
        const { data } = await axios({
          url: '/reports',
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: 'GET',
          responseType: 'json',
          withCredentials: true,
        });
        this.reports = data.reports;
      },
      selectReport (report) {
        Swal.fire({
          title: this.$t('ADD_REPORT'),
          text: this.$t('CONFIRM_REPORT_ADD', { reportName: report.name }),
          showCancelButton: true,
          icon: 'question',
          confirmButtonText: this.$t('ADD'),
          confirmButtonColor: this.$vuetify.theme.defaults.light.secondary,
        }).then((result) => {
          if (result.isConfirmed) {
            grid.addWidget({
              w: report.minWidth, reportID: report.id, h: report.minHeight, removable: true, content: `<div><button class='item-close-btn' onclick="document.removeItem(this.parentNode.parentNode.parentNode)">X</button><iframe style="padding: 15px; border: 0; overflow-y: hidden; position: absolute; top: 0; left: 0; bottom: 0; right: 0; width: 100%; height: 100%;" src="/#/${report.name.toLowerCase().replaceAll(' ', '-').normalize('NFD').replace(/[\u0300-\u036f]/g, '')}" ></iframe></div>`,
            });
            this.saveReport();
            this.reports = this.reports.filter((r) => r.id !== report.id);
            this.report = null;
          }
        });
      },
      async saveReport () {
        const gridToSave = grid.save(true, true);
        await axios({
          url: '/dashboard',
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: !this.dashboardId ? 'POST' : 'PUT',
          responseType: 'json',
          withCredentials: true,
          data: { data: gridToSave.children.length > 0 ? JSON.stringify(gridToSave.children) : '', id: this.dashboardId ? this.dashboardId : null },
        });
        if (!this.dashboardId) {
          this.fetchDashboard();
        }
        return grid.save(true, true);
      },
    },
  };
</script>
<style>
  .grid-stack {
    background: #e0e0e0;
    overflow-y: hidden;
    height: 100vh;
  }
  .grid-stack-item-content {
    background-color: #FFFFFF;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    border: 0;
    overflow-y: hidden;
    cursor: move;
  }
  .item-close-btn {
    font-weight: bold;
    cursor: pointer;
    position: absolute;
    right: 2px;
    z-index: 99;
    padding-left: 4px;
    padding-right: 4px;
    display: none;
  }
  .grid-stack-item-content:hover .item-close-btn {
    display: inline;
  }
  .item-close-btn:hover {
    background: rgba(4, 136, 185, 0.15);
    border-radius: 100%;
    padding-left: 4px;
    padding-right: 4px;
    -webkit-transition: background .5s ease-out;
    -moz-transition: background .5s ease-out;
    -o-transition: background .5s ease-out;
    transition: background .5s ease-out;
  }
</style>

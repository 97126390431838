<template>
  <v-container fluid fill-height style='height: 90vh'>
    <v-row no-gutters align='center' justify='center'>
      <div>
        <v-row no-gutters align='center' justify='center'>
          <v-img contain max-width='400' max-height='400' :src='require("../../assets/undraw-park.svg")' />
        </v-row>
        <v-row no-gutters align='center' justify='center' class='mt-5'>
          <v-card-title>
            {{$t('PAGE_ACCESS_DENIED')}}
          </v-card-title>
        </v-row>
      </div>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'AccessDenied',
  };
</script>
